import { language } from "shared/language";
import { convertArrToObj } from "shared/utils";

export const POPUP_DEFAULT_FILTER = {
  title: undefined,
  type: undefined,
};

export const POPUP_TYPE_WEB_PMS = "WEB_PMS";
export const POPUP_TYPE_APP_MEMBER = "APP_MEMBER";
export const POPUP_TYPE_APP_PMS = "APP_PMS";

export const POPUP_TYPE_LIST = [
  {
    name: language.web_pms,
    value: POPUP_TYPE_WEB_PMS,
  },
  {
    name: language.app_member,
    value: POPUP_TYPE_APP_MEMBER,
  },
  {
    name: language.app_pms,
    value: POPUP_TYPE_APP_PMS,
  },
];

export const POPUP_TYPE_LIST_OBJ = convertArrToObj(POPUP_TYPE_LIST, "value");
