import { Route } from "react-router-dom";
import BuildingRoute from "./buildingRoute";
import VersionRoute from "./versionRoute";
import IconRoute from "./iconRoute";
import NotificationRoute from "./notificationRoute";
import WhiteLabelRoute from "./whiteLabelRoute";
import CityRoute from "./cityRoute";
import DistrictRoute from "./district";
import WardRoute from "./ward";
import BannerRoute from "./bannerRoute";
import ServicePartnerRoute from "./servicePartner";
import AdsPartnerRoute from "./adsPartnerRoute";
import PartnerOrderRoute from "./partnerOrderRoute";
import BillLockRoute from "./billLockRoute";
import ConfigModuleForBuildingRoute from "./configModuleForBuilding";
import ModuleConfigRoute from "./moduleConfigRoute";
import RemoteConfigRoute from "./remoteConfigRoute";
import PlanConfigRoute from "./plan";
import MerchantRoute from "./merchantRoute";
import MerchantUtilityRoute from "./merchantUtilityRoute";
import MerchantOrderRoute from "./merchantOrderRoute";
import StorageRoute from "./storageRoute";
import ConfigModuleUpdateBuildingRoute from "./configModuleUpdateBuilding";
import CategoryHotDealRoute from "./categoryHotDeal";
import HotDealRoute from "./hotDeal";
import PaymentProviderRoute from "./paymentProviderRoute";
import OrganizationRoute from "./organization";
import SurveyRoute from "./surveyRoute";
import PromotionRoute from "./promotion";
import ConfigPopupRoute from "./popup";

function ManagementRoutes() {
  return (
    <Route path="management">
      {BuildingRoute()}
      {SurveyRoute()}
      {IconRoute()}
      {VersionRoute()}
      {WhiteLabelRoute()}
      {NotificationRoute()}
      {CityRoute()}
      {DistrictRoute()}
      {WardRoute()}
      {BannerRoute()}
      {RemoteConfigRoute()}
      {ServicePartnerRoute()}
      {CategoryHotDealRoute()}
      {HotDealRoute()}
      {AdsPartnerRoute()}
      {PartnerOrderRoute()}
      {MerchantOrderRoute()}
      {BillLockRoute()}
      {PartnerOrderRoute()}
      {ConfigModuleForBuildingRoute()}
      {ModuleConfigRoute()}
      {PlanConfigRoute()}
      {MerchantRoute()}
      {MerchantUtilityRoute()}
      {StorageRoute()}
      {ConfigModuleUpdateBuildingRoute()}
      {PaymentProviderRoute()}
      {PromotionRoute()}
      {OrganizationRoute()}
      {ConfigPopupRoute()}
    </Route>
  );
}

export default ManagementRoutes;
